import React, { useState } from "react";
import { useEffect } from "react";
import style from "./style.module.css";
import PanahBawahPolos from "../../Photo/panahBawahPolos.png";
import PanahBawahIsi from "../../Photo/panahBawahIsi.png";
import LogoSilangMerah from "../../Photo/logoSilangMerah.png";
import LogoCentangHijau from "../../Photo/logoCentangHijau.png";

const CashFlow = (props) => {
  const url = props.url;
  const [valueFilter,setValueFilter]=useState("");
  const [logoPanah,setLogoPanah]=useState(PanahBawahPolos);
  const[containerFilter,setContainerFilter]=useState("notVisibleContainerFilter");
  const [dataBarang, setDataBarang] = useState([]);
  const [divInput, setDivInput] = useState("notVisibleDivInput");
  const [valueNamaBarang, setValueNamaBarang] = useState("");
  const [valueHargaBarang, setValueHargaBarang] = useState("");
  const [valueQuantityBarang, setValueQuantityBarang] = useState("");
  const [valueCashFlow,setValueCashFlow]=useState("outcome");
  const [disabled,setDisabled]=useState(false);
  const [keterangan,setKeterangan]=useState("");
  const [penyimpanJsonNamaBarang , setPenyimpanJsonNamaBarang]=useState([]);
// function Klik Logo centang filter
const KlikLogoCentangFilter=()=>{
if(valueFilter!==""){
  setContainerFilter("notVisibleContainerFilter");
  setLogoPanah(PanahBawahIsi);
}else{
  setContainerFilter("notVisibleContainerFilter");
  setLogoPanah(PanahBawahPolos);
}
}
// function Klik logo silang filter
const KlikLogoSilangFilter=()=>{
  setValueFilter("");
  setContainerFilter("notVisibleContainerFilter");
  setLogoPanah(PanahBawahPolos);
  setDataBarang(penyimpanJsonNamaBarang);
}
  // function On Change filter
  const OnChangeFilter=(val)=>{
    setValueFilter(val);
    setDataBarang(penyimpanJsonNamaBarang.filter((item) =>
    item.nama_barang.toLowerCase().includes(val.toLowerCase()))
  )
   // console.log(val);
   // setDataBarang(dataBarang.filter((item)=>item.nama_barang === "a"));
  }
  // function klik panah filter
  const KlikPanah=(logoPanah)=>{

  setContainerFilter("visibleContainerFilter");


  }
  //
  async function AmbilDataBarang(val) {
    await fetch(url + "/?op=getDataInventoryGymPemasukan")
      .then((response) => response.json())
      .then((json) => {
        if(json!==null){
          setPenyimpanJsonNamaBarang(json);
        setDataBarang(json);}
        else{
          setDataBarang([]);
        }
      });
  }
  //
  const KlikTr = (val) => {
    setDivInput("visibleDivInput");
    setValueNamaBarang(val.nama_barang);
   
  };
  //
  const KlikNew = () => {
    setDivInput("visibleDivInput");
    setValueNamaBarang("");
    setValueHargaBarang("");
    setValueQuantityBarang("");
  };
  //
  const Kirim = () => {
    if(valueNamaBarang===""||valueHargaBarang===""
    ||valueQuantityBarang===""){
      setKeterangan("isi data dengan lengkap");
      setDisabled(false);
    }else{
    setDisabled(true);
    fetch(url + "/?op=inputCashFlowInventoryGym", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body:
        "nama_barang=" +
        valueNamaBarang +
        "&harga_barang=" +
        valueHargaBarang +
        "&quantity_barang=" +
        valueQuantityBarang+
        "&keterangan=" +
        valueCashFlow
    })
    .then((response)=>response.json())
    .then((json)=>{
        if(json==="berhasil"){
            setDivInput("notVisibleDivInput");
            AmbilDataBarang();
            setValueNamaBarang("");
            setValueHargaBarang("");
            setValueQuantityBarang("");
            setValueCashFlow("outcome");
            setDisabled(false);
            setKeterangan("");
        }else{
          setKeterangan("tidak ada internet");
          setDisabled(false);
        }
    })}
  };
  useEffect(() => {
    AmbilDataBarang();
  }, []);
  return (
    <div className={style.containerAll}>
      <div className={style.judul}>
        <div className={style.tulisanJudul}>Inventory Gym</div>
        <div onClick={() => KlikNew()} className={style.tulisanNew}>
          new
        </div>
      </div>
      <div className={style[`${divInput}`]}>
        <h2>Input</h2>
        <input
          onChange={(e) => setValueNamaBarang(e.target.value)}
          value={valueNamaBarang}
          placeholder="Nama Barang"
        />
        <input
          onChange={(e) => setValueHargaBarang(e.target.value)}
          value={valueHargaBarang}
          placeholder="Harga Barang Total"
          type="number"
        />
        <input
          onChange={(e) => setValueQuantityBarang(e.target.value)}
          value={valueQuantityBarang}
          placeholder="Quantity"
          type="number"
        />
        <select value={valueCashFlow} onChange={(e)=>setValueCashFlow(e.target.value)}>
            <option>
                outcome
            </option>
            <option>
                income
            </option>
          
        </select>
        <div className={style.keterangan}>{keterangan}</div>
        <button disabled={disabled} onClick={() => Kirim()}>Kirim</button>
      </div>
      <div className={style.divTable}>
        <table className={style.table}>
          <thead>
            <tr className={style.trSticky}>
              <th className={style.thNamaBarang}>Nama Barang
              <img className={style.logoPanah} src={logoPanah} onClick={()=>KlikPanah(logoPanah)}/>
              <div className={style[`${containerFilter}`]}>
               <div className={style.divContentFilter}>
                <input value={valueFilter} onChange={(e)=>OnChangeFilter(e.target.value)} placeholder="search" className={style.inputFilter}/>
                <img onClick={()=>KlikLogoCentangFilter()} src={LogoCentangHijau} className={style.imgCentang}/>
                <img onClick={()=>KlikLogoSilangFilter()} src={LogoSilangMerah} className={style.imgSilang}/>
               </div>
                </div></th>
            
            </tr>
          </thead>
          <tbody>
            {dataBarang.map((val, index) => (
              <tr
                style={{ cursor: "pointer" }}
                onClick={() => KlikTr(val)}
                key={index}
              >
                <td> {val.nama_barang}</td>
            
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default CashFlow;
