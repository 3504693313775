
import './App.css';
import React from 'react';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Home from './Page/Home';
import AttendanceGym from './Page/AttendanceGym';
import AttendanceZumba from './Page/AttendanceZumba';
import Owner from './Page/Owner';
import Gym from './Page/Gym';
import Zumba from './Page/Zumba';
import MemberBaruGym from './Page/MemberBaruGym';
import MemberBaruZumba from './Page/MemberBaruZumba';
import MemberPerpanjanganGym from './Page/MemberPerpanjanganGym';
import InventoryGym from './Page/InventoryGym';
import InventoryZumba from './Page/InventoryZumba';
import PengeluaranGym from './Page/PengeluaranGym';
import PemasukanGym from './Page/PemasukanGym';
import RekapanGym from './Page/RekapanGym';
import CashFlow from './Page/CashFlow';
import PengeluaranZumba from './Page/PengeluaranZumba';
import PemasukanZumba from './Page/PemasukanZumba';
import RekapanZumba from './Page/RekapanZumba';
import CashFlowZumba from './Page/CashFlowZumba';
import OwnerCashFlow from './Page/OwnerCashFlow';
import OwnerDatabase from './Page/OwnerDatabase';
import OwnerAfiliator from './Page/OwnerAfiliator';
import OwnerPajak from './Page/OwnerPajak';

const App=()=> {
  //const url = "https://192.168.55.136/gym/gym.php";
  const url = "https://monitoring.wonderwoman.site/gym.php";
 //const url ="https://09ab-182-2-71-244.ngrok-free.app/gym/gym.php";
  return (
   <BrowserRouter>
   <Routes>
   <Route path={'/'} element={<Home url={url} />}/>
   
   <Route path={'/zumba'} element={<Zumba url={url} />}/>
   <Route path={'/zumba/attendance'} element={<AttendanceZumba url={url} />}/>
   <Route path={'/zumba/attendance/memberBaru'} element={<MemberBaruZumba url={url} />}/>
   <Route path={'/zumba/inventory'} element={<InventoryZumba url={url} />}>
   <Route path={'pemasukan'} element={<PemasukanZumba url={url} />}/>
   <Route path={'rekapan'} element={<RekapanZumba url={url} />}/>
   <Route path={'cashflow'} element={<CashFlowZumba url={url} />}/>
   </Route>
  
   <Route path={'/gym'} element={<Gym url={url} />}/>
   <Route path={'/gym/attendance'} element={<AttendanceGym url={url} />}/>
   <Route path={'/gym/attendance/memberBaru'} element={<MemberBaruGym url={url} />}/>
   <Route path={'/gym/attendance/memberPerpanjangan'} element={<MemberPerpanjanganGym url={url} />}/>
   <Route path={'/gym/inventory'} element={<InventoryGym url={url} />}>
   <Route index element={<PengeluaranGym url={url}  />}/>
   <Route path={'pemasukan'} element={<PemasukanGym url={url} />}/>
   <Route path={'rekapan'} element={<RekapanGym url={url} />}/>
   <Route path={'cashflow'} element={<CashFlow url={url} />}/>
    </Route>
    <Route path={'/owner'} element={<Owner url={url} />}>
   <Route index element={<OwnerCashFlow url={url}  />}/>
   <Route path={'database'} element={<OwnerDatabase url={url} />}/>
   <Route path={'afiliator'} element={<OwnerAfiliator url={url} />}/>
   <Route path={'pajak'} element={<OwnerPajak url={url} />}/>
    </Route>
   </Routes>
   </BrowserRouter>
  );
}



export default App;

/*
<BrowserRouter>
<Routes>
<Route path={'/gym'} element={<Home />}/>
<Route path={'mt/sewing'} element={<Sewing />}>
<Route index element={<SewingHome />}/>
<Route path={'alokasi'} element={<SewingAlokasi url={url} />}/>
 </Route>
<Route path={'/jarum'} element={<Jarum />}/>
</Routes>
</BrowserRouter>
*/