import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import style from "./style.module.css";
import PanahBawahPolos from "../../Photo/panahBawahPolos.png";
import PanahBawahIsi from "../../Photo/panahBawahIsi.png";
import LogoPanah from "../../Photo/panah.png";
import LogoSilangMerah from "../../Photo/logoSilangMerah.png";
import LogoCentangHijau from "../../Photo/logoCentangHijau.png";
import LogoExcel from "../../Photo/logoExcel.png";
import * as XLSX from "xlsx";

const OwnerCashFlow = (props) => {
  const url = props.url;
  const [listData, setListData] = useState([]);
  const [totalPendapatan, setTotalPendapatan] = useState([]);
  const [totalPajak, setTotalPajak] = useState([]);
  const [valueFilterStatus, setValueFilterStatus] = useState("");
  const [nilaiTotalSaldo, setNilaiTotalSaldo] = useState("");
  const [dataBarangZumba, setDataBarangZumba] = useState([]);
  const [dataBarang, setDataBarang] = useState([]);
  const [dataFreelance, setDataFreelance] = useState([]);
  const [dataFreelanceZumba, setDataFreelanceZumba] = useState([]);
  const [divInput, setDivInput] = useState("notVisibleDivInput");
  const [valueNamaBarang, setValueNamaBarang] = useState("");
  const [valueHargaBarang, setValueHargaBarang] = useState("");
  const [valueQuantityBarang, setValueQuantityBarang] = useState("");
  const [totalIncomeInventory, setTotalIncomeInventory] = useState(0);
  const [totalIncomeFreelance, setTotalIncomeFreelance] = useState(0);
  const [totalOutcomeInventory, setTotalOutcomeInventory] = useState(0);
  const [totalIncomeInventoryZumba, setTotalIncomeInventoryZumba] = useState(0);
  const [totalIncomeFreelanceZumba, setTotalIncomeFreelanceZumba] = useState(0);
  const [totalOutcomeInventoryZumba, setTotalOutcomeInventoryZumba] =
    useState(0);
  const [jumlahDataFreelance, setJumlahDataFreelance] = useState(0);
  const [jumlahDataFreelanceZumba, setJumlahDataFreelanceZumba] = useState(0);
  const [nilaiDate, setNilaiDate] = useState("");
  const [logoPanah, setLogoPanah] = useState(PanahBawahPolos);
  const [logoPanahNama, setLogoPanahNama] = useState(PanahBawahPolos);
  const [divThKeterangan, setDivThKeterangan] = useState("divThKeteranganNone");
  const [jumlahDataFreelanceLS, setJumlahDataFreelanceLS] = useState(0);
  const [valueFilter, setValueFilter] = useState("");
  const [containerFilter, setContainerFilter] = useState(
    "notVisibleContainerFilter"
  );
  const [penyimpanJsonNama, setPenyimpanJsonNama] = useState([]);
  const [penyimpanJsonFreelance, setPenyimpanJsonFreelance] = useState([]);
  // function download excel
  const handleExportExcel = () => {
    // Copy listData and append totalPendapatan and totalPajak rows
    const dataToExport = [
      ...dataBarang,
      ...dataFreelanceZumba.map((item) => ({
        timestamp: item.timestamp, // Menjaga item 'timestamp'
        nama_barang: item.nama, // Mengganti 'nama' menjadi 'nama_barang'
        out_barang: item.jumlah, // Mengganti 'jumlah' menjadi 'out_barang'
        total: item.total,
        keterangan: "income", // Menambahkan field baru 'keterangan' dengan nilai 'income'
      })),
      {},
      {
        timestamp: "",
        nama_barang: "Item",
        out_barang: "Gym",
        total: "Zumba",
      },
      {
        timestamp: "",
        nama_barang: "Pemasukan",
        out_barang: totalIncomeInventory,
        total: totalIncomeInventoryZumba + totalIncomeFreelanceZumba,
      },
      {
        timestamp: "",
        nama_barang: "Pengeluaran",
        out_barang: totalOutcomeInventory,
        total: totalOutcomeInventoryZumba,
      },
      {
        timestamp: "",
        nama_barang: "Saldo",
        out_barang: totalIncomeInventory - totalOutcomeInventory,
        total:
          totalIncomeInventoryZumba +
          totalIncomeFreelanceZumba -
          totalOutcomeInventoryZumba,
      },
      {
        timestamp: "",
        nama_barang: "Total Saldo",
        out_barang:
          totalIncomeInventory -
          totalOutcomeInventory +
          totalIncomeInventoryZumba +
          totalIncomeFreelanceZumba -
          totalOutcomeInventoryZumba,
      },
    ];

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(wb, "Cashflow.xlsx");
  };
  // function Klik Logo centang filter
  const KlikLogoCentangFilter = () => {
    if (valueFilter !== "") {
      setContainerFilter("notVisibleContainerFilter");
      setLogoPanahNama(PanahBawahIsi);
    } else {
      setContainerFilter("notVisibleContainerFilter");
      setLogoPanahNama(PanahBawahPolos);
    }
  };
  // function Klik logo silang filter
  const KlikLogoSilangFilter = () => {
    let LSFilterNama = localStorage.getItem("filterNama");
    if (LSFilterNama === null) LSFilterNama = "";
    let LSFilterStatusDataBarang = localStorage.getItem(
      "filterStatusDataBarang"
    );
    if (LSFilterStatusDataBarang === null) LSFilterStatusDataBarang = "";
    setValueFilter("");
    setContainerFilter("notVisibleContainerFilter");
    setLogoPanahNama(PanahBawahPolos);
    localStorage.setItem("filterNama", "");
    setDataBarang(
      penyimpanJsonNama.filter(
        (item) =>
          item.nama_barang.toLowerCase().includes("".toLowerCase()) &&
          item.keterangan
            .toLowerCase()
            .includes(LSFilterStatusDataBarang.toLowerCase())
      )
    );
    if (
      LSFilterStatusDataBarang === "" ||
      LSFilterStatusDataBarang === "income"
    ) {
      setDataFreelance(
        penyimpanJsonFreelance.filter((item) =>
          item.status.toLowerCase().includes(LSFilterNama.toLowerCase())
        )
      );
    }
  };
  // function filter keterangan all income outcome
  const FilterKeterangan = () => {
    let LSFilterNama = localStorage.getItem("filterNama");
    if (LSFilterNama === null) LSFilterNama = "";
    let LSFilterStatusDataBarang = localStorage.getItem(
      "filterStatusDataBarang"
    );
    if (LSFilterStatusDataBarang === null) LSFilterStatusDataBarang = "";

    setDataBarang(
      penyimpanJsonNama.filter(
        (item) =>
          item.nama_barang.toLowerCase().includes(LSFilterNama.toLowerCase()) &&
          item.keterangan
            .toLowerCase()
            .includes(LSFilterStatusDataBarang.toLowerCase())
      )
    );
    if (
      LSFilterStatusDataBarang === "" ||
      LSFilterStatusDataBarang === "income"
    ) {
      setDataFreelance(
        penyimpanJsonFreelance.filter((item) =>
          item.status.toLowerCase().includes(LSFilterNama.toLowerCase())
        )
      );
    } else {
      setDataFreelance([]);
    }
  };
  // function On Change filter
  const OnChangeFilter = (val) => {
    let LSFilterStatusDataBarang = localStorage.getItem(
      "filterStatusDataBarang"
    );
    if (LSFilterStatusDataBarang === null) LSFilterStatusDataBarang = "";
    setValueFilter(val);
    localStorage.setItem("filterNama", val);
    setDataBarang(
      penyimpanJsonNama.filter(
        (item) =>
          item.nama_barang.toLowerCase().includes(val.toLowerCase()) &&
          item.keterangan
            .toLowerCase()
            .includes(LSFilterStatusDataBarang.toLowerCase())
      )
    );
    if (
      LSFilterStatusDataBarang === "" ||
      LSFilterStatusDataBarang === "income"
    ) {
      setDataFreelance(
        penyimpanJsonFreelance.filter((item) =>
          item.status.toLowerCase().includes(val.toLowerCase())
        )
      );
    }
    // console.log(val);
    // setDataBarang(dataBarang.filter((item)=>item.nama_barang === "a"));
  };
  // function klik panah filter
  const KlikPanah = (logoPanah) => {
    setContainerFilter("visibleContainerFilter");
  };
  //
  const ChangeDatePhp = (val) => {
    let LSFilterStatusDataBarang = localStorage.getItem(
      "filterStatusDataBarang"
    );
    let LSFilterNama = localStorage.getItem("filterNama");

    let LSDateOwnerCashFlowStart = localStorage.getItem(
      "dateOwnerCashFlowStart"
    );
    let LSDateOwnerCashFlowFinish = localStorage.getItem(
      "dateOwnerCashFlowFinish"
    );
    if (LSDateOwnerCashFlowStart === null) LSDateOwnerCashFlowStart = "";
    if (LSDateOwnerCashFlowFinish === null) LSDateOwnerCashFlowFinish = "";
    if (LSFilterStatusDataBarang === null) LSFilterStatusDataBarang = "";
    if (LSFilterNama === null) LSFilterNama = "";
    fetch(url + "/?op=getDataAllHistoryPengeluaranInventoryGym", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body:
        "dateStart=" +
        LSDateOwnerCashFlowStart +
        "&dateFinish=" +
        LSDateOwnerCashFlowFinish,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          setPenyimpanJsonNama(json);
          setDataBarang(
            json.filter(
              (item) =>
                item.nama_barang
                  .toLowerCase()
                  .includes(LSFilterNama.toLowerCase()) &&
                item.keterangan
                  .toLowerCase()
                  .includes(LSFilterStatusDataBarang.toLowerCase())
            )
          );
          let outcome = 0;
          let income = 0;
          let jsonIncome = json.filter((item) => item.keterangan === "income");
          let jsonOutcome = json.filter(
            (item) => item.keterangan === "outcome"
          );
          for (let i = 0; i < jsonIncome.length; i++) {
            income = parseInt(income) + parseInt(jsonIncome[i].total);
          }
          for (let i = 0; i < jsonOutcome.length; i++) {
            outcome = parseInt(outcome) + parseInt(jsonOutcome[i].total);
          }

          setTotalIncomeInventory(income);
          setTotalOutcomeInventory(outcome);
        } else {
          setDataBarang([]);
          setTotalIncomeInventory(0);
          setTotalOutcomeInventory(0);
        }
      });
    // batas fetch baru
    fetch(url + "/?op=getAmbilDataFreelanceRekapan", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body:
        "dateStart=" +
        LSDateOwnerCashFlowStart +
        "&dateFinish=" +
        LSDateOwnerCashFlowFinish,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          setPenyimpanJsonFreelance(json);
          if (
            LSFilterStatusDataBarang === "" ||
            LSFilterStatusDataBarang === "income"
          ) {
            setDataFreelance(
              json.filter((item) =>
                item.status.toLowerCase().includes(LSFilterNama.toLowerCase())
              )
            );
          }
          /*localStorage.setItem("jumlahDataFreelance", JSON.stringify(json));
          if (
            localStorage.getItem("filterStatusDataBarang") === "" ||
            localStorage.getItem("filterStatusDataBarang") === null
          ) {
            setDataFreelance(
              JSON.parse(localStorage.getItem("jumlahDataFreelance"))
            );
          }*/
          setJumlahDataFreelance(json.length);
          setTotalIncomeFreelance(json.length * 30000);
        } else {
          localStorage.setItem("jumlahDataFreelance", JSON.stringify([]));
          setDataFreelance([]);
          setJumlahDataFreelance(0);
          setTotalIncomeFreelance(0);
        }
      });
  };
  // ---- sudah Zumba
  const ChangeDatePhpZumba = (val) => {
    let LSDateOwnerCashFlowStart = localStorage.getItem(
      "dateOwnerCashFlowStart"
    );
    let LSDateOwnerCashFlowFinish = localStorage.getItem(
      "dateOwnerCashFlowFinish"
    );
    if (LSDateOwnerCashFlowStart === null) LSDateOwnerCashFlowStart = "";
    if (LSDateOwnerCashFlowFinish === null) LSDateOwnerCashFlowFinish = "";
    fetch(url + "/?op=getDataAllHistoryPengeluaranInventoryZumba", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body:
        "dateStart=" +
        LSDateOwnerCashFlowStart +
        "&dateFinish=" +
        LSDateOwnerCashFlowFinish,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          setDataBarangZumba(json);

          let outcome = 0;
          let income = 0;
          let jsonIncome = json.filter((item) => item.keterangan === "income");
          let jsonOutcome = json.filter(
            (item) => item.keterangan === "outcome"
          );
          for (let i = 0; i < jsonIncome.length; i++) {
            income = parseInt(income) + parseInt(jsonIncome[i].total);
          }
          for (let i = 0; i < jsonOutcome.length; i++) {
            outcome = parseInt(outcome) + parseInt(jsonOutcome[i].total);
          }

          setTotalIncomeInventoryZumba(income);
          setTotalOutcomeInventoryZumba(outcome);
        } else {
          setDataBarangZumba([]);
          setTotalIncomeInventoryZumba(0);
          setTotalOutcomeInventoryZumba(0);
        }
      });
    // batas fetch baru
    fetch(url + "/?op=getAmbilDataFreelanceRekapanZumba", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body:
        "dateStart=" +
        LSDateOwnerCashFlowStart +
        "&dateFinish=" +
        LSDateOwnerCashFlowFinish,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          let filterNama = json.map((item) => item.instruktur);
          let harga = {
            zinSusi: 25000,
            zinIcha: 30000,
            aerobic: 15000,
            yoga: 30000,
          };
          let dataFilterUnique = [...new Set(filterNama)];
          const hasil = dataFilterUnique.map((elemen) => {
            const jumlah = json.filter(
              (item) => item.instruktur === elemen
            ).length;
            const total = jumlah * harga[elemen];
            return { nama: elemen, jumlah: jumlah, total: total };
          });
          let hasil2 = json.map((item) => {
            let jumlah2 = harga[item.instruktur];
            return {
              timestamp: item.timestamp,
              nama: item.instruktur,
              jumlah: 1,
              total: jumlah2,
            };
          });
          setDataFreelanceZumba(hasil2);

          setJumlahDataFreelanceZumba(hasil.length);
          let totalIncome = 0;
          for (let i = 0; i < hasil.length; i++) {
            totalIncome = parseInt(totalIncome) + parseInt(hasil[i].total);
          }
          setTotalIncomeFreelanceZumba(totalIncome);
        } else {
          setDataFreelanceZumba([]);
          setJumlahDataFreelanceZumba(0);
          setTotalIncomeFreelanceZumba(0);
        }
      });
  };

  //
  async function AmbilDataBarang(val) {
    await fetch(url + "/?op=getDataAllHistoryPengeluaranInventoryGym")
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          setPenyimpanJsonNama(json);
          setDataBarang(json);

          let outcome = 0;
          let income = 0;
          let jsonIncome = json.filter((item) => item.keterangan === "income");
          let jsonOutcome = json.filter(
            (item) => item.keterangan === "outcome"
          );
          for (let i = 0; i < jsonIncome.length; i++) {
            income = parseInt(income) + parseInt(jsonIncome[i].total);
          }
          for (let i = 0; i < jsonOutcome.length; i++) {
            outcome = parseInt(outcome) + parseInt(jsonOutcome[i].total);
          }

          setTotalIncomeInventory(income);
          setTotalOutcomeInventory(outcome);
        } else {
          setDataBarang([]);
          setTotalIncomeInventory(0);
          setTotalOutcomeInventory(0);
        }
      });
  }

  //
  async function AmbilDataBarangZumba(val) {
    await fetch(url + "/?op=getDataAllHistoryPengeluaranInventoryZumba")
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          setDataBarangZumba(json);

          let outcome = 0;
          let income = 0;
          let jsonIncome = json.filter((item) => item.keterangan === "income");
          let jsonOutcome = json.filter(
            (item) => item.keterangan === "outcome"
          );
          for (let i = 0; i < jsonIncome.length; i++) {
            income = parseInt(income) + parseInt(jsonIncome[i].total);
          }
          for (let i = 0; i < jsonOutcome.length; i++) {
            outcome = parseInt(outcome) + parseInt(jsonOutcome[i].total);
          }

          setTotalIncomeInventoryZumba(income);
          setTotalOutcomeInventoryZumba(outcome);
        } else {
          setDataBarangZumba([]);
          setTotalIncomeInventoryZumba(0);
          setTotalOutcomeInventoryZumba(0);
        }
      });
  }
  //
  async function AmbilDataFreelance(val) {
    await fetch(url + "/?op=getAmbilDataFreelanceRekapan")
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          //  localStorage.setItem("jumlahDataFreelance", JSON.stringify(json));
          setPenyimpanJsonFreelance(json);
          setDataFreelance(json);
          /* setDataFreelance(
            JSON.parse(localStorage.getItem("jumlahDataFreelance"))
          );*/
          setJumlahDataFreelance(json.length);
          setTotalIncomeFreelance(json.length * 30000);
        } else {
          setDataFreelance([]);
          setJumlahDataFreelance(0);
          setTotalIncomeFreelance(0);
        }
      });
  }

  //
  async function AmbilDataFreelanceZumba(val) {
    await fetch(url + "/?op=getAmbilDataFreelanceRekapanZumba")
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          let filterNama = json.map((item) => item.instruktur);
          let harga = {
            zinSusi: 25000,
            zinIcha: 30000,
            aerobic: 15000,
            yoga: 30000,
          };
          let dataFilterUnique = [...new Set(filterNama)];
          const hasil = dataFilterUnique.map((elemen) => {
            const jumlah = json.filter(
              (item) => item.instruktur === elemen
            ).length;
            const total = jumlah * harga[elemen];
            return { nama: elemen, jumlah: jumlah, total: total };
          });
          let hasil2 = json.map((item) => {
            let jumlah2 = harga[item.instruktur];
            return {
              timestamp: item.timestamp,
              nama: item.instruktur,
              jumlah: 1,
              total: jumlah2,
            };
          });
          setDataFreelanceZumba(hasil2);

          setJumlahDataFreelanceZumba(hasil.length);
          let totalIncome = 0;
          for (let i = 0; i < hasil.length; i++) {
            totalIncome = parseInt(totalIncome) + parseInt(hasil[i].total);
          }
          setTotalIncomeFreelanceZumba(totalIncome);
        } else {
          setDataFreelanceZumba([]);
          setJumlahDataFreelanceZumba(0);
          setTotalIncomeFreelanceZumba(0);
        }
      });
  }
  //
  const KlikTabel = () => {};
  //
  const ChangeDate = (val, id) => {
    switch (id) {
      case "start":
        localStorage.setItem("dateOwnerCashFlowStart", val);
        ChangeDatePhp(val);
        ChangeDatePhpZumba(val);
        break;
      case "finish":
        localStorage.setItem("dateOwnerCashFlowFinish", val);
        ChangeDatePhp(val);
        ChangeDatePhpZumba(val);
        break;
      default:
        break;
    }
  };
  //
  /*const ChangeDate = (val) => {
    localStorage.setItem("dateOwnerCashFlow",val);
    setNilaiDate(val);
    ChangeDatePhp(val);
    ChangeDatePhpZumba(val);
  };*/
  //
  /*useEffect(()=>{
    let LSFilterNama = localStorage.getItem("filterNama");
    let LSFilterStatusDataBarang = localStorage.getItem("filterStatusDataBarang");
    if(LSFilterNama === null)LSFilterNama ="";
    if(LSFilterStatusDataBarang === null)LSFilterStatusDataBarang ="";
setDataBarang(penyimpanJsonNama.filter((item)=>(item.nama_barang.toLowerCase().includes(LSFilterNama.toLowerCase()))&&(
item.keterangan.toLowerCase().includes(LSFilterStatusDataBarang.toLowerCase()))))
  },[localStorage.getItem("filterNama"),localStorage.getItem("filterStatusDataBarang")])*/
  //
  useEffect(() => {
    localStorage.removeItem("filterStatusDataBarang");
    localStorage.removeItem("filterNama");
    localStorage.removeItem("dateOwnerCashFlowStart");
    localStorage.removeItem("dateOwnerCashFlowFinish");

    localStorage.removeItem("filterStatusDataBarang");
    AmbilDataBarang();
    AmbilDataBarangZumba();
    AmbilDataFreelance();
    AmbilDataFreelanceZumba();
  }, []);
  useEffect(() => {
    // Set an interval to refocus the input field every 100 ms
    const interval = setInterval(() => {
      ChangeDatePhp();
      ChangeDatePhpZumba();
    }, 5000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className={style.containerAll}>
      <div className={style.containerKiri}>
        <div className={style.divAtas}>
          <input
            type="date"
            onChange={(e) => ChangeDate(e.target.value, "start")}
          />
          <div className={style.divLogoPanah}>
            <img src={LogoPanah} alt="logo_panah" />
          </div>
          <input
            type="date"
            onChange={(e) => ChangeDate(e.target.value, "finish")}
          />
        </div>
        <div className={style.tulisanCashFlow}>
          Total Cash Flow
          <div className={style.divLogoExcels}>
            <img src={LogoExcel} onClick={handleExportExcel} />
          </div>
        </div>
        <div className={style.tableCashFlow}>
          <table className={style.table}>
            <thead>
              <tr
                onClick={() => {
                  KlikTabel();
                }}
              >
                <th>Item</th>
                <th>Gym</th>
                <th>Zumba</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Pemasukan</td>
                <td>{totalIncomeInventory}</td>
                <td>{totalIncomeInventoryZumba + totalIncomeFreelanceZumba}</td>
              </tr>
              <tr>
                <td>Pengeluaran</td>
                <td>{totalOutcomeInventory}</td>
                <td>{totalOutcomeInventoryZumba}</td>
              </tr>
              <tr>
                <td>Saldo</td>
                <td>{totalIncomeInventory - totalOutcomeInventory}</td>
                <td>
                  {" "}
                  {totalIncomeInventoryZumba +
                    totalIncomeFreelanceZumba -
                    totalOutcomeInventoryZumba}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={style.divTotalSaldo}>
          <div>Total Saldo</div>
          <div>
            {" "}
            {totalIncomeInventory -
              totalOutcomeInventory +
              totalIncomeInventoryZumba +
              totalIncomeFreelanceZumba -
              totalOutcomeInventoryZumba}
          </div>
        </div>
      </div>
      <div className={style.containerKanan}>
        <div className={style.containerKananPemasukan}>
          <div className={style.judulDetailGym}>Detail Cash Flow Gym</div>
          <div className={style.isiDetailGym}>
            <table className={style.tableGym}>
              <thead>
                <tr className={style.trSticky}>
                  <th>Timestamp</th>
                  <th className={style.thNamaBarang}>
                    Nama
                    <img
                      className={style.logoPanah}
                      src={logoPanahNama}
                      onClick={() => KlikPanah(logoPanah)}
                    />
                    <div className={style[`${containerFilter}`]}>
                      <div className={style.divContentFilter}>
                        <input
                          value={valueFilter}
                          onChange={(e) => OnChangeFilter(e.target.value)}
                          placeholder="search"
                          className={style.inputFilter}
                        />
                        <img
                          onClick={() => KlikLogoCentangFilter()}
                          src={LogoCentangHijau}
                          className={style.imgCentang}
                        />
                        <img
                          onClick={() => KlikLogoSilangFilter()}
                          src={LogoSilangMerah}
                          className={style.imgSilang}
                        />
                      </div>
                    </div>
                  </th>
                  <th>Jumlah</th>
                  <th>Total</th>
                  <th className={style.thKeterangan}>
                    Status
                    <img
                      onClick={() => {
                        if (divThKeterangan === "divThKeteranganVisible") {
                          setDivThKeterangan("divThKeteranganNone");
                        } else {
                          setDivThKeterangan("divThKeteranganVisible");
                        }
                      }}
                      src={logoPanah}
                    />
                    <div className={style[`${divThKeterangan}`]}>
                      <div
                        onClick={() => {
                          // setDataBarang(JSON.parse(localStorage.getItem("dataBarang")));
                          setLogoPanah(PanahBawahPolos);
                          setDivThKeterangan("divThKeteranganNone");
                          /*  setDataFreelance(
                            JSON.parse(
                              localStorage.getItem("jumlahDataFreelance")
                            )
                          );*/
                          localStorage.setItem("filterStatusDataBarang", "");
                          FilterKeterangan();
                        }}
                      >
                        all
                      </div>
                      <div
                        onClick={() => {
                          // setDataBarang(JSON.parse(localStorage.getItem("dataBarang")).filter((item)=>item.keterangan === "outcome"));
                          setLogoPanah(PanahBawahIsi);
                          setDivThKeterangan("divThKeteranganNone");
                          //  setDataFreelance([]);
                          localStorage.setItem(
                            "filterStatusDataBarang",
                            "outcome"
                          );
                          FilterKeterangan();
                        }}
                      >
                        outcome
                      </div>
                      <div
                        onClick={() => {
                          // setDataBarang(JSON.parse(localStorage.getItem("dataBarang")).filter((item)=>item.keterangan === "income"));
                          setLogoPanah(PanahBawahIsi);
                          setDivThKeterangan("divThKeteranganNone");
                          /*setDataFreelance(
                            JSON.parse(
                              localStorage.getItem("jumlahDataFreelance")
                            )
                          );*/

                          localStorage.setItem(
                            "filterStatusDataBarang",
                            "income"
                          );
                          FilterKeterangan();
                        }}
                      >
                        income
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataBarang.map((val, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}> {val.timestamp}</td>
                    <td style={{ textAlign: "center" }}> {val.nama_barang}</td>
                    <td style={{ textAlign: "center" }}>{val.out_barang}</td>
                    <td style={{ textAlign: "center" }}>{val.total}</td>
                    <td style={{ textAlign: "center" }}>{val.keterangan}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className={style.containerKananPengeluaran}>
          <div className={style.judulDetailZumba}>Detail Cash Flow Zumba</div>
          <div className={style.isiDetailGym}>
            <table className={style.tableGym}>
              <thead>
                <tr className={style.trSticky}>
                  <th>Timestamp</th>
                  <th>Nama</th>
                  <th>Jumlah</th>
                  <th>Total</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {dataBarangZumba.map((val, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}> {val.timestamp}</td>
                    <td style={{ textAlign: "center" }}> {val.nama_barang}</td>
                    <td style={{ textAlign: "center" }}>{val.out_barang}</td>
                    <td style={{ textAlign: "center" }}>{val.total}</td>
                    <td style={{ textAlign: "center" }}>{val.keterangan}</td>
                  </tr>
                ))}
                {dataFreelanceZumba.map((val, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}> {val.timestamp}</td>
                    <td style={{ textAlign: "center" }}>
                      {" "}
                      freelance {val.nama}
                    </td>
                    <td style={{ textAlign: "center" }}>1</td>
                    <td style={{ textAlign: "center" }}>{val.total}</td>
                    <td style={{ textAlign: "center" }}>income</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OwnerCashFlow;
