import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.css";
import { useNavigate } from "react-router-dom";
import LogoUsaha from "../../Photo/logoUsaha.png";

const AttendanceGym = (props) => {
  const navigate = useNavigate();
  const url = props.url;
  const inputRef = useRef(null);
  const [valueInput, setValueInput] = useState("");
  const [valueHasilNama, setValueHasilNama] = useState("");
  const [valueHasilDate, setValueHasilDate] = useState("");
  const [prosesScan, setProsesScan] = useState(true);
  const [tulisanSudahScanAtas, setTulisanSudahScanAtas] = useState("");
  const [tulisanSudahScanBawah, setTulisanSudahScanBawah] = useState("");
  // Function to handle input change
  const handleInputChange = (val) => {
    setValueInput(val);
    let today = new Date();
    let year = today.getFullYear();
    let month = String(today.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
    let day = String(today.getDate()).padStart(2, "0");

    let formattedDate = `${year}-${month}-${day}`;
    setValueHasilDate(formattedDate);

    // Check if the input length is 10
    if (/[^0-9]/.test(val)) {
      setValueHasilNama("Kartu Error");
      setValueInput("");
      setTimeout(() => {
        setValueHasilNama("");
      }, 1000);
    } else if (val.length === 10) {
      setProsesScan(false);
      fetch(url + "/?op=getNomorRfid", {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: "rfid=" + val + "&date=" + formattedDate,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json !== null) {
            if (json === "freelance") {
              setTulisanSudahScanAtas("Keep Healthy :)");
              setTulisanSudahScanBawah("Freelance Visitor");
            } else if (json === "tidak ada koneksi") {
              setTulisanSudahScanAtas("Ups Koneksi internet tidak ada :(");
            } else if (json === "data tidak ditemukan") {
              localStorage.setItem("rfidVisitorGym", val);
              navigate("/gym/attendance/memberBaru");
            } else if (json[0] === "expired") {
              localStorage.setItem("rfidVisitorGym", val);
              localStorage.setItem("namaGymPerpanjangan", json[2]);
              localStorage.setItem("alamatGymPerpanjangan", json[3]);
              localStorage.setItem("nomorHpGymPerpanjangan", json[4]);
              localStorage.setItem("startGymPerpanjangan", json[5]);
              localStorage.setItem("expiredGymPerpanjangan", json[6]);
              navigate("/gym/attendance/memberPerpanjangan");
            } else {
              setProsesScan(false);
              setTulisanSudahScanAtas("Keep Healthy :)");
              setTulisanSudahScanBawah(json);
            }
            setValueInput("");

            // Clear the input after 1000 ms
            setTimeout(() => {
              setProsesScan(true);
              setTulisanSudahScanAtas("");
              setTulisanSudahScanBawah("");
            }, 1000);
          }
        });
    }
    /*
        else
        if(val === "0008236765" ||val === "0008236468" ){
            setProsesScan(false);
            setTulisanSudahScanAtas("Keep Healthy :)");
            setTulisanSudahScanBawah("freelance visitor");
            fetch(url + "/?op=inputFreelance", {
                method: "post",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: "rfid=" + val 
            })
            .then((response)=>response.json())
            .then((json)=>{
                if(json === "tidak ada koneksi"){
                    setProsesScan(false);
setTulisanSudahScanAtas("Ups Koneksi internet tidak ada :(");
                }else{
                    setProsesScan(false);
                    setTulisanSudahScanAtas("Keep Healthy :)");
                    setTulisanSudahScanBawah(json);
                }
                setValueInput("");
              
                // Clear the input after 1000 ms
                setTimeout(() => {
                    setProsesScan(true);
                    
                }, 1000);
            })
        }
        else 
        if ((val.length === 10 && val !== "0008236765") ||(val.length === 10 && val !== "0008236468") ) {
            localStorage.setItem("rfidVisitorGym",val);
            fetch(url + "/?op=getNamaScan", {
                method: "post",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: "rfid=" + val +
                "&date="+ valueHasilDate // Assuming 'val' contains the RFID value
            })
            .then((response) => response.json())
            .then((json) => {
                if(json === "tidak ada koneksi"){
                    setProsesScan(false);
setTulisanSudahScanAtas("Ups Koneksi internet tidak ada :(");
                }else if(json === "data tidak ditemukan"){

navigate('/gym/attendance/memberBaru');
                } else if(json[0]==="expired"){
                 
                    localStorage.setItem("namaGymPerpanjangan",json[2]);
                    localStorage.setItem("alamatGymPerpanjangan",json[3]);
                    localStorage.setItem("nomorHpGymPerpanjangan",json[4]);
                    localStorage.setItem("startGymPerpanjangan",json[5]);
                    localStorage.setItem("expiredGymPerpanjangan",json[6]);
                navigate('/gym/attendance/memberPerpanjangan');
                }else{
                    setProsesScan(false);
                    setTulisanSudahScanAtas("Keep Healthy :)");
                    setTulisanSudahScanBawah(json);
                }
                
               
                setValueInput("");
              
                // Clear the input after 1000 ms
                setTimeout(() => {
                    setProsesScan(true);
                    
                }, 1000);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
        }
        */
  };

  //function jika belum scan
  const BelumScan = () => {
    return (
      <div className={style.divKotakTap}>
        <input
          placeholder="Tap your card please :)"
          value={valueHasilNama}
          readOnly={true}
          className={style.inputField}
        />
      </div>
    );
  };

  //function jika sudah scan
  const SudahScan = () => {
    return (
      <div className={style.divSudahScan}>
        <div className={style.sudahScanAtas}>{tulisanSudahScanAtas}</div>
        <div className={style.sudahScanBawah}>{tulisanSudahScanBawah}</div>
      </div>
    );
  };

  //use effect
  useEffect(() => {
    // Initially focus the input field
    if (inputRef.current) {
      inputRef.current.focus();
    }

    // Set an interval to refocus the input field every 100 ms
    const interval = setInterval(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className={style.containerAll}>
      <input
        className={style.inputBayangan}
        value={valueInput}
        onChange={(e) => handleInputChange(e.target.value)}
        ref={inputRef}
      />
      <div className={style.tulisanAttendance}>
        <img onClick={() => navigate("/gym")} src={LogoUsaha} />
        Attendance Gym
      </div>
      {prosesScan === true ? BelumScan() : SudahScan()}
    </div>
  );
};

export default AttendanceGym;
