import React, { useState } from "react";
import { useEffect } from "react";
import style from "./style.module.css";
import PanahBawahPolos from "../../Photo/panahBawahPolos.png";
import PanahBawahIsi from "../../Photo/panahBawahIsi.png";
import LogoPanah from "../../Photo/panah.png";
import LogoSilangMerah from "../../Photo/logoSilangMerah.png";
import LogoCentangHijau from "../../Photo/logoCentangHijau.png";
const RekapanGym = (props) => {

  const url = props.url;
  const [dataBarang, setDataBarang] = useState([]);

  const [dataFreelance, setDataFreelance] = useState([]);
  const [divInput, setDivInput] = useState("notVisibleDivInput");
  const [valueNamaBarang, setValueNamaBarang] = useState("");
  const [valueHargaBarang, setValueHargaBarang] = useState("");
  const [valueQuantityBarang, setValueQuantityBarang] = useState("");
  const [totalIncomeInventory, setTotalIncomeInventory] = useState(0);
  const [totalIncomeFreelance, setTotalIncomeFreelance] = useState(0);
  const [totalOutcomeInventory, setTotalOutcomeInventory] = useState(0);
  const [jumlahDataFreelance, setJumlahDataFreelance] = useState(0);
  const [nilaiDate, setNilaiDate] = useState("");
  const [logoPanah, setLogoPanah] = useState(PanahBawahPolos);
  const [divThKeterangan, setDivThKeterangan] = useState("divThKeteranganNone");
  const [jumlahDataFreelanceLS, setJumlahDataFreelanceLS] = useState(0);
  const [valueFilter, setValueFilter] = useState("");
  const [valueFilterKeterangan, setValueFilterKeterangan] = useState("");
  const [logoPanahBarang, setLogoPanahBarang] = useState(PanahBawahPolos);
  const [containerFilter, setContainerFilter] = useState(
    "notVisibleContainerFilter"
  );
  const [penyimpanJsonNamaBarang, setPenyimpanJsonNamaBarang] = useState([]);
  // function Change Date
  const ChangeDate = (val, id) => {
    setValueFilter("");
    setValueFilterKeterangan("");
    setContainerFilter("notVisibleContainerFilter");
    setLogoPanahBarang(PanahBawahPolos);
    setLogoPanah(PanahBawahPolos);
    setDivThKeterangan("divThKeteranganNone");
    switch (id) {
        case "start":
            localStorage.setItem("dateRekapGymStart", val);
            ChangeDatePhp();
            break;
        case "finish":
            localStorage.setItem("dateRekapGymFinish", val);
            ChangeDatePhp();
            break;
        default:
            break;
    }
};
  // function update filter scan
  const Filter = () => {
    setDataBarang(
      penyimpanJsonNamaBarang.filter(
        (item) =>
          item.nama_barang.toLowerCase().includes(valueFilter.toLowerCase()) &&
          item.keterangan.includes(valueFilterKeterangan.toLowerCase())
      )
    );
  };

  // function Klik Logo centang filter
  const KlikLogoCentangFilter = () => {
    if (valueFilter !== "") {
      setContainerFilter("notVisibleContainerFilter");
      setLogoPanahBarang(PanahBawahIsi);
    } else {
      setContainerFilter("notVisibleContainerFilter");
      setLogoPanahBarang(PanahBawahPolos);
    }
  };
  // function Klik logo silang filter
  const KlikLogoSilangFilter = () => {
    setValueFilter("");
    setContainerFilter("notVisibleContainerFilter");
    setLogoPanahBarang(PanahBawahPolos);
   
  };
  // function On Change filter
  const OnChangeFilter = (val) => {

    setValueFilter(val);
   /* setDataBarang(
      penyimpanJsonNamaBarang.filter((item) =>
        item.nama_barang.toLowerCase().includes(val.toLowerCase())
      )
    );*/
    // console.log(val);
    // setDataBarang(dataBarang.filter((item)=>item.nama_barang === "a"));
  };
  // function klik panah filter
  const KlikPanah = (logoPanahBarang) => {
    setContainerFilter("visibleContainerFilter");
  };
  //
  const ChangeDatePhp = (val) => {
    fetch(url + "/?op=getDataAllHistoryPengeluaranInventoryGym", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body:  "dateStart=" +
      localStorage.getItem("dateRekapGymStart") +
      "&dateFinish=" +
      localStorage.getItem("dateRekapGymFinish"),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          localStorage.setItem("dataBarang", JSON.stringify(json));
          setDataBarang(json);
          setPenyimpanJsonNamaBarang(json);

          let outcome = 0;
          let income = 0;
          if (json.filter((item) => item.keterangan === "income").length > 0) {
            let jsonIncome = json.filter(
              (item) => item.keterangan === "income"
            );
            for (let i = 0; i < jsonIncome.length; i++) {
              income = parseInt(income) + parseInt(jsonIncome[i].total);
            }
            setTotalIncomeInventory(income);
          } else {
            setTotalIncomeInventory(0);
          }
          if (json.filter((item) => item.keterangan === "outcome").length > 0) {
            let jsonOutcome = json.filter(
              (item) => item.keterangan === "outcome"
            );
            for (let i = 0; i < jsonOutcome.length; i++) {
              outcome = parseInt(outcome) + parseInt(jsonOutcome[i].total);
            }
            setTotalOutcomeInventory(outcome);
          } else {
            setTotalOutcomeInventory(0);
          }
        } else {
          localStorage.setItem("dataBarang", JSON.stringify([]));
          setDataBarang([]);
          setPenyimpanJsonNamaBarang([]);
          setTotalIncomeInventory(0);
          setTotalOutcomeInventory(0);
        }
      });
    
   
  };
  //
  async function AmbilDataBarang(val) {
    await fetch(url + "/?op=getDataAllHistoryPengeluaranInventoryGym")
      .then((response) => response.json())
      .then((json) => {
     
        if (json !== null) {
          localStorage.setItem("dataBarang", JSON.stringify(json));
          setDataBarang(json);
          setPenyimpanJsonNamaBarang(json);
          let outcome = 0;
          let income = 0;
          if (json.filter((item) => item.keterangan === "income").length > 0) {
            let jsonIncome = json.filter(
              (item) => item.keterangan === "income"
            );
            for (let i = 0; i < jsonIncome.length; i++) {
              income = parseInt(income) + parseInt(jsonIncome[i].total);
            }
            setTotalIncomeInventory(income);
          } else {
            setTotalIncomeInventory(0);
          }
          if (json.filter((item) => item.keterangan === "outcome").length > 0) {
            let jsonOutcome = json.filter(
              (item) => item.keterangan === "outcome"
            );
            for (let i = 0; i < jsonOutcome.length; i++) {
              outcome = parseInt(outcome) + parseInt(jsonOutcome[i].total);
            }
            setTotalOutcomeInventory(outcome);
          } else {
            setTotalOutcomeInventory(0);
          }
        } else {
          setDataBarang([]);
          setPenyimpanJsonNamaBarang([]);
          setTotalIncomeInventory(0);
          setTotalOutcomeInventory(0);
        }
      });
  }
 
  //
  const KlikTr = (val) => {
    setDivInput("visibleDivInput");
    setValueNamaBarang(val.nama_barang);
    setValueHargaBarang(val.harga_barang);
    setValueQuantityBarang(val.stok_barang);
  };
  //
  const KlikNew = () => {
    setDivInput("visibleDivInput");
    setValueNamaBarang("");
    setValueHargaBarang("");
    setValueQuantityBarang("");
  };
  //
 /* const ChangeDate = (val) => {
    setNilaiDate(val);
    ChangeDatePhp(val);
  };*/
  //
  const Kirim = () => {
    fetch(url + "/?op=inputPemasukanInventoryGym", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body:
        "nama_barang=" +
        valueNamaBarang +
        "&harga_barang=" +
        valueHargaBarang +
        "&quantity_barang=" +
        valueQuantityBarang,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json === "berhasil") {
          setDivInput("notVisibleDivInput");
          AmbilDataBarang();
        }
      });
  };
  //
  useEffect(() => {
    localStorage.removeItem("dataBarang");
    localStorage.removeItem("dateRekapGymStart");
    localStorage.removeItem("dateRekapGymFinish");
    AmbilDataBarang();
   
  }, []);
// use Effect jika filter
useEffect(()=>{
Filter();
},[valueFilter,valueFilterKeterangan])
  return (
    <div className={style.containerAll}>
      <div className={style.judul}>
        <div className={style.tulisanJudul}>Rekapan Inventory Gym</div>
       
      </div>
      <div className={style.divAtas}>
               
                <input type="date" onChange={(e) => ChangeDate(e.target.value, "start")} />
                <div className={style.divLogoPanah}>
                    <img src={LogoPanah} alt="logo_panah" />
                </div>
                <input type="date" onChange={(e) => ChangeDate(e.target.value, "finish")} />
            </div>

      <div className={style.divTable}>
        <table className={style.table}>
          <thead>
            <tr className={style.trSticky}>
              <th>
                Timestamp
              </th>
              <th className={style.thNamaBarang}>
                Nama Barang
                <img
                  className={style.logoPanah}
                  src={logoPanahBarang}
                  onClick={() => KlikPanah(logoPanah)}
                />
                 <div className={style[`${containerFilter}`]}>
                  <div className={style.divContentFilter}>
                    <input
                      value={valueFilter}
                      onChange={(e) => OnChangeFilter(e.target.value)}
                      placeholder="search"
                      className={style.inputFilter}
                    />
                    <img
                      onClick={() => KlikLogoCentangFilter()}
                      src={LogoCentangHijau}
                      className={style.imgCentang}
                    />
                    <img
                      onClick={() => KlikLogoSilangFilter()}
                      src={LogoSilangMerah}
                      className={style.imgSilang}
                    />
                  </div>
                </div>
              </th>
              <th>Jumlah</th>
              <th>Total Harga</th>
              <th className={style.thKeterangan}>
                Keterangan
                <img
                  onClick={() => {
                    if (divThKeterangan === "divThKeteranganVisible") {
                      setDivThKeterangan("divThKeteranganNone");
                    } else {
                      setDivThKeterangan("divThKeteranganVisible");
                    }
                  }}
                  src={logoPanah}
                />
                <div className={style[`${divThKeterangan}`]}>
                  <div
                    onClick={() => {
                     setValueFilterKeterangan("");
                      setLogoPanah(PanahBawahPolos);
                      setDivThKeterangan("divThKeteranganNone");
                      setJumlahDataFreelanceLS(
                        localStorage.getItem("jumlahDataFreelance")
                      );
                    }}
                  >
                    all
                  </div>
                  <div
                    onClick={() => {
                      setValueFilterKeterangan("outcome");
                      setLogoPanah(PanahBawahIsi);
                      setDivThKeterangan("divThKeteranganNone");
                      setJumlahDataFreelanceLS(0);
                    }}
                  >
                    outcome
                  </div>
                  <div
                    onClick={() => {
                      setValueFilterKeterangan("income");
                      setLogoPanah(PanahBawahIsi);
                      setDivThKeterangan("divThKeteranganNone");
                      setJumlahDataFreelanceLS(
                        localStorage.getItem("jumlahDataFreelance")
                      );
                    }}
                  >
                    income
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {dataBarang.map((val, index) => (
              <tr
                style={{ cursor: "pointer" }}
                onClick={() => KlikTr(val)}
                key={index}
              >
                <td> {val.timestamp}</td>
                <td> {val.nama_barang}</td>
                <td>{val.out_barang}</td>
                <td>{val.total}</td>
                <td>{val.keterangan}</td>
              </tr>
            ))}
          
          </tbody>
        </table>
      </div>
      <div className={style.divTotalRekap}>
        <div>total income : {totalIncomeInventory }</div>
        <div>total outcome : {totalOutcomeInventory}</div>
        <div>
          saldo :{" "}
          {totalIncomeInventory - totalOutcomeInventory}
        </div>
      </div>
    </div>
  );
};
export default RekapanGym;
