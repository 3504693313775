import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { json } from "react-router-dom";
import style from "./style.module.css";
import PanahBawahPolos from "../../Photo/panahBawahPolos.png";
import PanahBawahIsi from "../../Photo/panahBawahIsi.png";
import LogoSilangMerah from "../../Photo/logoSilangMerah.png";
import LogoCentangHijau from "../../Photo/logoCentangHijau.png";
import LogoPanah from "../../Photo/panah.png";

const OwnerAfiliator = (props) => {
  const url = props.url;
  const [valueFilterNamaAfiliator,setValueFilterNamaAfiliator]=useState("");
  const [dataAfiliatorGym, setDataAfiliatorGym] = useState([]);
  const [dataAfiliatorZumba, setDataAfiliatorZumba] = useState([]);
  const [dataDetailAfiliatorZumba, setDataDetailAfiliatorZumba] = useState([]);
  const [dataDetailAfiliatorGym, setDataDetailAfiliatorGym] = useState([]);
  const [divId, setDivId] = useState("afiliatorZumba");
  const [valueFilter,setValueFilter]=useState("");
  const [logoPanah,setLogoPanah]=useState(PanahBawahPolos);
  const[containerFilter,setContainerFilter]=useState("notVisibleContainerFilter");
 const [penyimpanJsonNamaBarang , setPenyimpanJsonNamaBarang]=useState([]);
  // function Change Date
  const ChangeDate = (val, id) => {
    setValueFilter("");
    localStorage.removeItem("filterNamaAfiliator");
    setContainerFilter("notVisibleContainerFilter");
 
    setLogoPanah(PanahBawahPolos);
   
    switch (id) {
        case "start":
            localStorage.setItem("dateAfiliatorGymStart", val);
            FilterBy(penyimpanJsonNamaBarang);
            break;
        case "finish":
            localStorage.setItem("dateAfiliatorGymFinish", val);
            FilterBy(penyimpanJsonNamaBarang);
            break;
        default:
            break;
    }
};
// function Klik Logo centang filter
const KlikLogoCentangFilter=()=>{
if(valueFilter!==""){
  setContainerFilter("notVisibleContainerFilter");
  setLogoPanah(PanahBawahIsi);
}else{
  setContainerFilter("notVisibleContainerFilter");
  setLogoPanah(PanahBawahPolos);
}
}
// function Klik logo silang filter
const KlikLogoSilangFilter=()=>{
  setValueFilter("");
  localStorage.removeItem("filterNamaAfiliator");
  setContainerFilter("notVisibleContainerFilter");
  setLogoPanah(PanahBawahPolos);
 // setDataBarang(penyimpanJsonNamaBarang);
}
  // function On Change filter
  const OnChangeFilter=(val)=>{
    setValueFilter(val);
    localStorage.setItem("filterNamaAfiliator",val);
  //  setDataBarang(penyimpanJsonNamaBarang.filter((item) =>
   // item.nama_barang.toLowerCase().includes(val.toLowerCase()))
 // )
   // console.log(val);
   // setDataBarang(dataBarang.filter((item)=>item.nama_barang === "a"));
  }
  // function klik panah filter
  const KlikPanah=(logoPanah)=>{
   
  setContainerFilter("visibleContainerFilter");
  }
// use effect ketika filter nama diganti
useEffect(()=>{
FilterBy(penyimpanJsonNamaBarang);
},[valueFilter])
  // function untuk filter berdasarkan apa
  const FilterBy=(val)=>{
    
  let LSFilterNamaAfiliator = localStorage.getItem("filterNamaAfiliator");
  let LSDateAfiliatorGymStart = localStorage.getItem("dateAfiliatorGymStart");
  let LSDateAfiliatorGymFinish = localStorage.getItem("dateAfiliatorGymFinish");
 
 // Berikan nilai default jika localStorage kosong
 if (!LSFilterNamaAfiliator) LSFilterNamaAfiliator = "";
 if (!LSDateAfiliatorGymStart) LSDateAfiliatorGymStart = "";
 if (!LSDateAfiliatorGymFinish) LSDateAfiliatorGymFinish = "";

 // Konversi string ke Date, cek apakah valid
 let startDate = LSDateAfiliatorGymStart ? new Date(LSDateAfiliatorGymStart) : null;
 let endDate = LSDateAfiliatorGymFinish ? new Date(LSDateAfiliatorGymFinish) : null;
 
  

   setDataDetailAfiliatorGym(val.filter(
    (item) =>
    (item.nama_afiliator.toLowerCase().includes(LSFilterNamaAfiliator.toLowerCase()) &&
      (!startDate || startDate <= new Date(item.awal_pendaftaran))&&
      (!endDate || endDate >= new Date(item.expired_member)))))
  }
  // fungsi ambil data afiliator gym
  async function AmbilDataAfiliatorGym() {
    let today = new Date();
    let year = today.getFullYear();
    let month = String(today.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
    let day = String(today.getDate()).padStart(2, "0");

    let formattedDate = `${year}-${month}-${day}`;
    await fetch(url + "/?op=getDataAfiliatorGym")
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          let filterExpired = json.filter(
            (item) =>
              item.expired_member >= formattedDate &&
              item.nama_afiliator !== "null"
          );

          let filterNamaAfiliator = filterExpired.map((item) => ({
            nama_afiliator: item.nama_afiliator,
            nomor_hp_afiliator: item.nomor_hp_afiliator,
          }));

          let dataFilter = filterNamaAfiliator;
          let dataFilterUniqueObj = dataFilter.reduce((acc, item) => {
            const key = `${item.nama_afiliator}-${item.nomor_hp_afiliator}`;
            if (!acc[key]) {
              acc[key] = item;
            }
            return acc;
          }, {});

          // Convert back to an array
          let dataFilterUnique = Object.values(dataFilterUniqueObj);

          const result = dataFilterUnique.map((dataFilterUnique) => {
            const count = json.filter(
              (item) =>
                item.nama_afiliator === dataFilterUnique.nama_afiliator &&
                item.nomor_hp_afiliator ===
                  dataFilterUnique.nomor_hp_afiliator &&
                item.expired_member >= formattedDate
            ).length;
            return { ...dataFilterUnique, jumlah: count };
          });
  
       setPenyimpanJsonNamaBarang(filterExpired);
          FilterBy(filterExpired);
          //setDataDetailAfiliatorGym(filterExpired);
          setDataAfiliatorGym(result);
        } else {
          setDataDetailAfiliatorGym([]);
          setDataAfiliatorGym([]);
        }
      });
  }
  // ambl data afiliator zumba
  const AmbilDataAfiliatorZumba = () => {
    fetch(url + "/?op=getDataAfiliatorZumba", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "date=" + localStorage.getItem("dateAfiliatorZumba"),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          setDataDetailAfiliatorZumba(
            json.filter((item) => item.instruktur !== "-")
          );

          let filterNamaTrainer = json
            .filter((item) => item.instruktur !== "-")
            .map((item) => ({ nama: item.instruktur }));

          let dataFilterUniqueObj = filterNamaTrainer.reduce((acc, item) => {
            const key = `${item.nama}`;
            if (!acc[key]) {
              acc[key] = item;
            }
            return acc;
          }, {});

          // Convert back to an array
          let dataFilterUnique = Object.values(dataFilterUniqueObj);
          const result = dataFilterUnique.map((dataFilterUnique) => {
            const count = json.filter(
              (item) => item.instruktur === dataFilterUnique.nama
            ).length;
            return { ...dataFilterUnique, jumlah: count };
          });

          setDataAfiliatorZumba(result);
        } else {
          setDataAfiliatorZumba([]);
          setDataDetailAfiliatorZumba([]);
        }
      });
  };
  //
  const ChangeDateAfiliatorZumba = (val) => {
    localStorage.setItem("dateAfiliatorZumba", val);
    AmbilDataAfiliatorZumba();
  };
  // klik div id
  const KlikDivId = (val) => {
    setDivId(val);
  };
  // detail zumba
  const DetailZumba = () => {
    return (
      <div className={style.divDetailZumba}>
        <div className={style.judulDetail}>Detail Afiliator Zumba</div>
        <div className={style.isiDetail}>
          <table className={style.tableAfiliator}>
            <thead>
              <tr className={style.trSticky}>
                <th>Timestamp</th>
                <th>Nama</th>
                <th>Afiliator</th>
              </tr>
            </thead>
            <tbody>
              {dataDetailAfiliatorZumba.map((item, index) => (
                <tr key={index}>
                  <td>{item.timestamp}</td>
                  <td>{item.nama}</td>
                  <td>{item.instruktur}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  // detail gym
  const DetailGym = () => {
    return (
      <div className={style.divDetailZumba}>
        <div className={style.judulDetail}>Detail Afiliator Gym</div>
        <div className={style.divAtas}>
               
               <input type="date" onChange={(e) => ChangeDate(e.target.value, "start")} />
               <div className={style.divLogoPanah}>
                   <img src={LogoPanah} alt="logo_panah" />
               </div>
               <input type="date" onChange={(e) => ChangeDate(e.target.value, "finish")} />
           </div>
        <div className={style.isiDetail}>
          <table className={style.tableAfiliator}>
            <thead>
              <tr className={style.trSticky}>
                <th>Nama</th>
                <th>Nomor hp</th>
                <th className={style.thNamaBarang}>Afiliator
              <img className={style.logoPanah} src={logoPanah} onClick={()=>KlikPanah(logoPanah)}/>
              <div className={style[`${containerFilter}`]}>
               <div className={style.divContentFilter}>
                <input value={valueFilter} onChange={(e)=>OnChangeFilter(e.target.value)} placeholder="search" className={style.inputFilter}/>
                <img onClick={()=>KlikLogoCentangFilter()} src={LogoCentangHijau} className={style.imgCentang}/>
                <img onClick={()=>KlikLogoSilangFilter()} src={LogoSilangMerah} className={style.imgSilang}/>
               </div>
                </div></th>
                <th>Awal Pendaftaran</th>
                <th>Expired Member</th>
              </tr>
            </thead>
            <tbody>
              {dataDetailAfiliatorGym.map((item, index) => (
                <tr key={index}>
                  <td>{item.nama}</td>
                  <td>{item.nomor_hp}</td>
                  <td>{item.nama_afiliator}</td>
                  <td>{item.awal_pendaftaran}</td>
                  <td>{item.expired_member}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  // use effect
  useEffect(() => {
    localStorage.removeItem("dateAfiliatorGymStart");
    localStorage.removeItem("dateAfiliatorGymFinish");
    localStorage.removeItem("filterNamaAfiliator");
    localStorage.setItem("dateAfiliatorZumba", "");
    AmbilDataAfiliatorGym();
    AmbilDataAfiliatorZumba();
  }, []);

  useEffect(() => {
    // Set an interval 
    const interval = setInterval(() => {
      AmbilDataAfiliatorGym();
      AmbilDataAfiliatorZumba();
    }, 5000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className={style.containerAll}>
      <div className={style.containerKiri}>
        <div className={style.divAfiliatorGym}>
          <div
            onClick={() => KlikDivId("afiliatorGym")}
            style={{
              cursor: "pointer",
              color: divId === "afiliatorGym" ? "#2f69fd" : "",
              borderBottom: divId === "afiliatorGym" ? "solid 1px #2f69fd" : "",
            }}
            className={style.judulAfiliatorGym}
          >
            Afiliator Member Gym
          </div>
          <div className={style.isiAfiliatorGym}>
            <table className={style.tableAfiliator}>
              <thead>
                <tr>
                  <th>Nama</th>
                  <th>Nomor Hp</th>
                  <th>Jumlah Member Aktif</th>
                </tr>
              </thead>
              <tbody>
                {dataAfiliatorGym.map((item, index) => (
                  <tr key={index}>
                    <td>{item.nama_afiliator}</td>
                    <td>{item.nomor_hp_afiliator}</td>
                    <td>{item.jumlah}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className={style.divAfiliatorGym}>
          <input
            onChange={(e) => ChangeDateAfiliatorZumba(e.target.value)}
            type="date"
          />
          <div
            onClick={() => KlikDivId("afiliatorZumba")}
            style={{
              cursor: "pointer",
              color: divId === "afiliatorZumba" ? "#2f69fd" : "",
              borderBottom:
                divId === "afiliatorZumba" ? "solid 1px #2f69fd" : "",
            }}
            className={style.judulAfiliatorGym}
          >
            Kelas Zumba
          </div>
          <div className={style.isiAfiliatorGym}>
            <table className={style.tableAfiliator}>
              <thead>
                <tr>
                  <th>Nama</th>
                  <th>Pengunjung</th>
                </tr>
              </thead>
              <tbody>
                {dataAfiliatorZumba.map((item, index) => (
                  <tr key={index}>
                    <td>{item.nama}</td>
                    <td>{item.jumlah}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className={style.containerKanan}>
        {divId === "afiliatorZumba" ? DetailZumba() : DetailGym()}
      </div>
    </div>
  );
};
export default OwnerAfiliator;
